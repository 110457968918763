import { render, staticRenderFns } from "./TopHeader.vue?vue&type=template&id=1a82ccd8&scoped=true"
import script from "./TopHeader.vue?vue&type=script&lang=js"
export * from "./TopHeader.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1a82ccd8",
  null
  
)

export default component.exports