<template>
    <div>
        <!-- Page Header -->
        <div class="row">
            <div class="col-12 col-sm-6 text-center text-sm-left mb-4 mb-sm-0">
                <span class="text-uppercase page-subtitle">Setup & Configurations
                </span>
                <h3 class="page-title">Top Header</h3>
            </div>
        </div>
        <hr>
        <!-- End Page Header -->

        <CRow class="my-4 justify-content-center">
            <CCol>
                <CCard>
                    <CCardBody>
                        <a-form-model ref="ruleForm" @submit.prevent="onSubmit()">
                            <a-form-item>
                                <a-checkbox v-model="topHeader.is_top_nav_showable">Show Top Header</a-checkbox>
                            </a-form-item>
                            <a-form-item>
                                <a-input
                                    :disabled="!topHeader.is_top_nav_showable"
                                    size="default"
                                    v-model="topHeader.top_nav_email"
                                    placeholder="Top Header Email"
                                    v-decorator="['email',{rules: [{ required: true, message: 'Please enter email address to show on top header' },{type: 'email',message: 'The input is not valid E-mail!'}]},]"
                                >
                                    <a-icon slot="prefix" type="mail" :style="{ color: 'rgba(0,0,0,.25)' }"/>
                                </a-input>
                            </a-form-item>
                            <a-form-item>
                                <a-input
                                    :disabled="!topHeader.is_top_nav_showable"
                                    v-model="topHeader.top_nav_phone"
                                    size="default"
                                    placeholder="Top Header Phone"
                                    v-decorator="[{rules: [{ required: true, message: 'Please enter phone number to show on top header' }]},]"
                                >
                                    <a-icon slot="prefix" type="phone" :style="{ color: 'rgba(0,0,0,.25)' }"/>
                                </a-input>
                            </a-form-item>
                            <a-form-item>
                                <a-textarea
                                    :disabled="!topHeader.is_top_nav_showable"
                                    v-model="topHeader.top_nav_offer_text"
                                    placeholder="Top Header offer text"
                                    auto-size
                                />
                            </a-form-item>
                            <a-form-item>
                                <a-textarea
                                    :disabled="!topHeader.is_top_nav_showable"
                                    v-model="topHeader.top_nav_offer_link"
                                    placeholder="Top header offer link"
                                    auto-size
                                />
                            </a-form-item>
                            <a-button type="primary" :loading="busy" :disabled="busy" @click="onSubmit"
                                class="float-right mt-4">
                                Update
                            </a-button>
                        </a-form-model>
                    </CCardBody>
                </CCard>
            </CCol>
        </CRow>
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
    name: "TopHeader",
    data() {
        return {
            busy: false,
            showTopHeader:false,
        }
    },
    methods: {
        ...mapActions(['TOP_HEADER_UPDATE']),
        onSubmit() {
            this.busy = true;
            this.TOP_HEADER_UPDATE().finally(() => {
                this.$notification['success']({
                    message: 'congratulations',
                    description: 'Top header config updated successfully!',
                    style: { marginTop: '41px' },
                });
                this.busy = false;
            })
        }
    },
    created() {
        if (this.topHeader === '') this.$store.dispatch('TOP_HEADER_SETUP');
    },
    computed: {
        ...mapGetters(["topHeader"])
    },
}
</script>

<style scoped></style>